import { gql } from '@apollo/client';
import * as MENUS from '../constants/menus';
import { BlogInfoFragment } from '../fragments/GeneralSettings';
import {
  Header,
  Footer,
  Main,
  Container,
  ContentWrapper,
  EntryHeader,
  NavigationMenu,
  FeaturedImage,
  SEO,
  PostTranslations
} from '../components';
import { useRouter } from "next/router";
import  Link  from  'next/link';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { flatListToHierarchical } from '@faustwp/core';
import GET_PAGE from '../queries/test';
 
export default function Component(props) {
  const { locale: activeLocale, locales } = useRouter();

  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  const { title: siteTitle, description: siteDescription } =
    props?.data?.generalSettings;
  const primaryMenu = props?.data?.headerMenuItems?.nodes ?? [];
  const footerMenu = props?.data?.footerMenuItems?.nodes ?? [];
  const { title, content, featuredImage, translations, editorBlocks } = props?.data?.page ?? { title: '' };
  console.log(editorBlocks)
  const blocks = flatListToHierarchical(editorBlocks, {idKey: 'clientId', parentKey: 'clientParentId'});
  console.log(blocks)

  return (
    <>
      <SEO
        title={siteTitle}
        description={siteDescription}
        imageUrl={featuredImage?.node?.sourceUrl}
      />
      <Header
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
      />
      <Main>
        <>
          <EntryHeader title={title} image={featuredImage?.node} />
          <Container>
            <PostTranslations translations={translations}></PostTranslations>
            <WordPressBlocksViewer blocks={blocks}></WordPressBlocksViewer>
            {/* <ContentWrapper content={content} /> */}
          </Container>
        </>
      </Main>
      <Footer title={siteTitle} menuItems={footerMenu} />
    </>
  );
}

Component.variables = ({databaseId, language}, ctx) => {

  const menuLocations = {
    'EN' : MENUS.PRIMARY_LOCATION,
    'ES' : MENUS.PRIMARY_ES,
    'DE' : MENUS.PRIMARY_DE
  }
  const translatedLanguage = ctx?.locale === 'en' ? 'ES' : 'EN';
  return {
    databaseId,
    headerLocation: menuLocations[language.code],
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
    language: translatedLanguage
  };
};

Component.query = GET_PAGE;

// Component.query = gql`
//   ${BlogInfoFragment}
//   ${NavigationMenu.fragments.entry}
//   ${FeaturedImage.fragments.entry}
//   query GetPageData(
//     $databaseId: ID!
//     $headerLocation: MenuLocationEnum
//     $footerLocation: MenuLocationEnum
//     $asPreview: Boolean = false
//   ) {
//     page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
//       title
//       content
//       editorBlocks {
//         name
//         renderedHtml
//         clientId
//         parentClientId
//       }
//       translations {
//         uri
//         language {
//           code
//         }
//       }
//       ...FeaturedImageFragment
//     }
//     generalSettings {
//       ...BlogInfoFragment
//     }
//     footerMenuItems: menuItems(where: { location: $footerLocation }) {
//       nodes {
//         ...NavigationMenuItemFragment
//       }
//     }
//     headerMenuItems: menuItems(where: { location: $headerLocation }) {
//       nodes {
//         ...NavigationMenuItemFragment
//       }
//     }
//   }
// `;
